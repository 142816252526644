import { ErrorHandler, Injectable } from '@angular/core';
import { ApplicationInsightsService } from './application-insights.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
  constructor(
    private readonly applicationInsightsService: ApplicationInsightsService
  ) {
    super();
  }

  override handleError(error: any) {
    const errorMessage: string = error?.message ?? '';
    const typeName: string = error?.name ?? '';

    const ignorableMessages = [
      'Cannot read properties of undefined',
      'Cannot read properties of null',
      "reading 'map'",
      "reading 'find'",
    ];

    const isIgnorable =
      typeName === 'TypeError' &&
      ignorableMessages.some((pattern) => errorMessage.includes(pattern));

    if (!isIgnorable) {
      this.applicationInsightsService.logException(error);
    }
  }
}
