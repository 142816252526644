<div class="seller-header">
  <h1>Detalhes do vendedor</h1>
  <div class="seller-detail">
    <div class="type-logo">
      <div class="logo-container">
        <mat-icon
          [inline]="true"
          class="material-icons logo"
          [svgIcon]="'dsh-ic-person'"
        >
        </mat-icon>
      </div>
    </div>
    @if (sellerGeneralData) {
      <div class="info">
        <span class="title" id="name">{{ sellerGeneralData.name }}</span>
        <span class="semibold"
        >Código:
        <span class="subtitle" id="code">{{ sellerGeneralData.code }}</span></span
        >

        <div class="row">
        <span class="semibold"
        >Último acesso:
          <span class="subtitle" id="lastConnection">{{
              sellerGeneralData.lastConnection | date : "dd/MM/yyyy"
            }}</span></span
        >
          <span class="semibold"
          >Conexoes mensais:
          <span class="subtitle" id="monthlyConnections">{{
              sellerGeneralData.monthlyConnections
            }}</span></span
          >
        </div>
      </div>
    }
  </div>
  <div class="list-item">
    @if (!(showNetworkStatus$ | async)) {
      <app-alert-message [status]="offlineStatus"></app-alert-message>
    }
  </div>
</div>
