import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './core/auth.service';
import { DeviceService } from './core/device.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Suvinil';
  isIframe = false;
  loginDisplay$: Observable<boolean> = this.authService.loginDisplayObs;
  isMobile = this.deviceService.isMobile();

  constructor(
    private authService: AuthService,
    private deviceService: DeviceService
  ) {}

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
  }
}
