<div class="container-metrics">
  <div class="content-metrics">
    <div class="table">
      <div class="title">Atendimento</div>
      <div class="detail">
        <div class="row">
          <div class="label">Cliente mais buscado:</div>
          <div class="value">{{ sellerStatistics.mostSearchedClient }}</div>
        </div>
        <div class="row">
          <div class="label">Quantidade de clientes buscados:</div>
          <div class="value">{{ searchedClientsText }}</div>
        </div>
        <div class="row">
          <div class="label">Clientes não buscados:</div>
          <div class="value">{{ notSearchedClientsText }}</div>
        </div>
      </div>
      @if (true) {
      <div class="table" [ngClass]="desktop ? '' : 'margin'">
        <div class="title">
          Vendas no último mês ({{
            sellerSellStatistics.salesDate | date : "MMMM"
          }})
        </div>
        <div class="detail">
          <div class="row">
            <div class="label">Clientes que compraram:</div>
            <div class="value">{{ uniqueClientsSoldToText }}</div>
          </div>
          <div class="row">
            <div class="label">Categorias de produtos vendidas:</div>
            <div class="value">{{ uniqueProductsCategoriesSoldText }}</div>
          </div>
          <div class="row">
            <div class="label">Produtos vendidos:</div>
            <div class="value">{{ uniqueProductsSoldText }}</div>
          </div>
        </div>
      </div>
      }
    </div>
    @if (true) {
    <div class="coverage-precision margin">
      <div class="coverage">
        <div class="title">Cobertura</div>
        <div class="icon">
          <mat-icon
            [inline]="true"
            class="material-icons"
            svgIcon="dsh-ic-coverage"
          >
          </mat-icon>
        </div>
        <div class="percentage">{{ sellerSellStatistics.coverage }}%</div>
      </div>
      <div class="precision">
        <div class="title">Precisão</div>
        <div class="icon">
          <mat-icon
            [inline]="true"
            class="material-icons"
            svgIcon="dsh-ic-precision"
          >
          </mat-icon>
        </div>
        <div class="percentage">{{ sellerSellStatistics.precision }}%</div>
      </div>
    </div>
    }
  </div>
</div>
