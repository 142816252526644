import { Component, Input, OnInit } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { NetworkService } from 'src/app/core/network.service';
import { FilterProduct } from 'src/app/modules/product/product.model';
import { ProductService } from 'src/app/modules/product/product.service';
import { FilterClient } from '../../../modules/client/client.model';
import { ClientService } from '../../../modules/client/client.service';

export enum FilterType {
  Client = 'client',
  Product = 'product',
}

@Component({
  selector: 'app-filter-access',
  templateUrl: './filter-access.component.html',
  styleUrls: ['./filter-access.component.scss'],
})
export class FilterAccessComponent implements OnInit {
  activeFilter: boolean = false;
  showNetworkStatus$!: Observable<boolean>;

  @Input() title?: string;
  @Input() routeAccess?: string;
  @Input() filterType!: FilterType;

  constructor(
    private readonly networkService: NetworkService,
    private readonly clientService: ClientService,
    private readonly productService: ProductService
  ) {}

  ngOnInit(): void {
    let filters: FilterClient | FilterProduct | null;
    if (this.filterType === FilterType.Client) {
      filters = this.clientService.getFilters();
      this.activeFilter = !!filters?.states?.[0]?.id;
    }

    if (this.filterType === FilterType.Product) {
      filters = this.productService.getFilters();
      if (filters) {
        this.activeFilter =
          filters?.selectedType !== 1 || filters?.selectedCategory.length > 0;
      }
    }

    this.showNetworkStatus$ = this.networkService.networkStatus.pipe(
      tap((status) => {
        if (!status) {
          this.activeFilter = false;
        }
      })
    );
  }
}
