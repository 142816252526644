import { ItemMultiSelect } from 'src/app/shared/components/multiselect/multiselect.component';
import { Product } from '../product/product.model';

export interface ClientItem {
  name: string;
  status: string;
  id: string;
  cnpj: string;
  recommendations?: Product[];
  customer_type_id: number;
  customer_type_name: string;
}

export interface ClientDetail {
  id: number;
  legal_name: string;
  name: string;
  email: string;
  phone: string;
  cnpj: string;
  status: string;
  address: string;
  neighborhood: string;
  state: string;
  city: string;
  customer_type_id: number;
  customer_type_name: string;
}

export interface FilterClient {
  states: ItemMultiSelect[];
  cities: ItemMultiSelect[];
  neighborhoods: ItemMultiSelect[];
  selectedOption: number;
}

export interface Comment {
  id: number;
  text: string;
  edited_date?: string;
  date: string;
}

export enum DefaultClientStatus {
  text = 'private',
}

export interface CustomerType {
  id: number;
  name: string;
  icon: string;
}

const segmentsById = new Map<number, CustomerType>([
  [1, { id: 1, name: 'LOJA DE TINTAS', icon: 'ic-segment-paint-store' }],
  [
    2,
    {
      id: 2,
      name: 'LOJA DE MATERIAIS PARA CONSTRUCAO',
      icon: 'ic-segment-construction-materials-store',
    },
  ],
  [
    3,
    {
      id: 3,
      name: 'LOJA DE FERRAGENS E FERRAMENTAS',
      icon: 'ic-segment-hardware-store',
    },
  ],
  [
    4,
    {
      id: 4,
      name: 'LOJA DE MATERIAL ELÉTRICO',
      icon: 'ic-segment-electrical-materials-store',
    },
  ],
  [
    5,
    { id: 5, name: 'LOJA DE HIDRÁULICA', icon: 'ic-segment-hydraulic-store' },
  ],
  [6, { id: 6, name: 'LOJA DE MADEIRA', icon: 'ic-segment-lumber-store' }],
  [7, { id: 7, name: 'LOJA DE PISCINA', icon: 'ic-segment-pool-store' }],
  [8, { id: 8, name: 'AGROPECUÁRIA', icon: 'ic-segment-agricultural-store' }],
  [9, { id: 9, name: 'MERCADO', icon: 'ic-segment-market' }],
  [10, { id: 10, name: 'OUTROS', icon: 'ic-segment-others' }],
]);

export function getCustomerTypeById(id: number): CustomerType {
  return (
    segmentsById.get(id) || {
      id: 10,
      name: 'OUTROS',
      icon: 'ic-segment-others',
    }
  );
}
