<div class="empty-item" [ngClass]="status">
  <div class="icon">
    <mat-icon
      [inline]="true"
      class="material-icons logo"
      [svgIcon]="'ic-attention-' + status"
    >
    </mat-icon>
  </div>
  <div class="message">
    <ng-content></ng-content>
  </div>

  @if (status === statusEnum.Offline) {
  <div class="message">
    <span>
      <b>Ops, você está sem internet. </b>
    </span>
    <br />
    <span>
      Lembre-se de conectar o app na rede para ver os dados completos do
      cliente.
    </span>
  </div>
  }
</div>
