import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, EMPTY, finalize, Observable, tap } from 'rxjs';
import {
  ApplicationInsightsService,
  EventName,
} from 'src/app/core/application-insights.service';
import { NetworkService } from 'src/app/core/network.service';
import { Status } from '../../../shared/components/alert-message/alert-message.component';
import { SellerKey } from '../../../shared/components/dsh-seller-item/seller-item.component';
import {
  MonthlyConnections,
  Seller,
  SellerSellStatistics,
  SellerStatistics,
} from '../seller.model';
import { SellerService } from '../seller.service';

@Component({
  selector: 'app-detail-list',
  templateUrl: './seller-detail.component.html',
  styleUrls: ['./seller-detail.component.scss'],
})
export class SellerDetailComponent implements OnInit {
  status: Status = Status.Warning;
  noSellers: boolean = false;
  showNetworkStatus$!: Observable<boolean>;
  offlineStatus: Status = Status.Offline;

  isLoading: boolean = false;

  sellerSellStatistics!: SellerSellStatistics;
  sellerStatistics!: SellerStatistics;

  searchText: string = '';
  messageList: string = 'Vendedor não encontrado.';
  elements: any[] = ['id', 'code', 'lastConnection', 'monthlyConnections'];
  date = new Date();
  sellerId: string = '';

  sellerGeneralData!: Seller;
  constructor(
    private readonly sellerService: SellerService,
    private readonly route: ActivatedRoute,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly networkService: NetworkService,
    private readonly routerService: Router
  ) {
    this.showNetworkStatus$ = this.networkService.networkStatus;
  }

  ngOnInit(): void {
    this.sellerId = this.route.snapshot.paramMap.get('sellerId')!;

    this.sellerService
      .getSellerStatistics(this.sellerId)
      .pipe(
        tap((client: SellerStatistics) => {
          this.sellerStatistics = client;
        }),
        finalize(() => (this.isLoading = false)),
        catchError(() => {
          this.noSellers = true;
          this.applicationInsightsService.logEvent(EventName.NotFoud, {
            stateNotFound: 'seller',
          });
          return EMPTY;
        })
      )
      .subscribe();

    this.sellerService
      .getSellerSellStatistics(this.sellerId)
      .pipe(
        tap((client: SellerSellStatistics) => {
          this.sellerSellStatistics = client;
        }),
        finalize(() => (this.isLoading = false)),
        catchError(() => {
          this.noSellers = true;
          this.applicationInsightsService.logEvent(EventName.NotFoud, {
            stateNotFound: 'seller',
          });
          return EMPTY;
        })
      )
      .subscribe();

    this.getSellerGeneralData();
  }

  getSellerGeneralData() {
    const data = localStorage.getItem(SellerKey.Seller);
    if (data) {
      this.sellerGeneralData = JSON.parse(data);
    } else {
      this.routerService.navigate(['seller/list']);
    }
  }

  searchbox(value: string): void {
    this.searchText = value;
  }

  toSorted(sellers: Seller[]): Seller[] {
    return sellers.sort((a, b) => b.monthlyConnections - a.monthlyConnections);
  }

  hasConnections(connections: MonthlyConnections[]) {
    return connections.every((item: any) => item.value === 0);
  }
}
