<div class="progress">
  <div style="display: flex; flex-direction: row; align-items: center">
    <div class="label">Clientes analisados em relação ao total:</div>
    <div class="progress-container">
      <div
        class="progress-bar"
        [ngStyle]="{
          'background-color': visualizations > 30 ? '#008502' : '#BD2529'
        }"
        [style.width]="progress + '%'"
      ></div>
    </div>
  </div>
  <div class="detail-progress">
    {{ analyzed_clients }} de {{ total_clients }} ({{
      visualizations | number : "1.1-1"
    }}%)
  </div>
</div>
