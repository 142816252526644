export const environment = {
  production: false,
  serviceWorker: true,
  publicClient: false,
  metrics: false,
  instrumentationKey: 'ca5d00b0-2bf0-47f8-927d-7bfa51d536c9',
  amplitud: {
    eneable: false,
    amplitudeKey: '6290f74d547b6b804c446eaf7ecd35a4',
  },
  manuals: {
    access: 'https://sway.cloud.microsoft/s/PD4WC4OPH4SquLUj/embed',
    usability: 'https://sway.cloud.microsoft/s/uwcSEazSAbqduGNt/embed',
  },
  msalConfig: {
    auth: {
      clientId: 'fb92f7c7-1989-4b3f-a38b-34d9ede352ce',
      authority:
        'https://login.microsoftonline.com/ecaa386b-c8df-4ce0-ad01-740cbdb5ba55',
    },
    redirectStartPage: '/client/list',
    sessionStorage: true,
  },
  apiConfig: {
    scopes: ['api://fb92f7c7-1989-4b3f-a38b-34d9ede352ce/user_impersonation'],
    uri: 'https://qa.vendas-ia.basf.com/api/',
  },
};
