import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

const ICONS_CONFIG = [
  'ic-filter',
  'ic-round-arrow-back',
  'ic-logout',
  'ic-download',
  'ic-search',
  'ic-delete',
  'ic-right-row',
  'ic-close-circle',
  'ic-close-square',
  'ic-wallet',
  'ic-public',
  'ic-right-circle',
  'ic-left-circle',
  'ic-arrow-left-circle',
  'ic-public-gray',
  'ic-wallet-blue',
  'ic-bar-1',
  'ic-bar-2',
  'ic-bar-3',
  'ic-bar-4',
  'ic-bar-5',
  'ic-hand-good-selected',
  'ic-hand-good',
  'ic-hand-bad-selected',
  'ic-hand-bad',
  'ic-close',
  'ic-logo',
  'ic-attention-error',
  'ic-attention-warning',
  'ic-attention-offline',
  'ic-check',
  'ic-message',
  'ic-trash',
  'ic-trash-disabled',
  'ic-edit',
  'ic-expand-more',
  'ic-expand-less',
  'ic-offline',
  'ic-help',
  'dsh-ic-hand-bad',
  'dsh-ic-hand-good',
  'dsh-ic-null',
  'dsh-ic-person',
  'dsh-ic-icon',
  'dsh-ic-icon-white',
  'dsh-ic-coverage',
  'dsh-ic-precision',
  'dsh-ic-excel',
  'dsh-ic-report',
  'dsh-ic-report-white',
  'ic-segment-paint-store',
  'ic-segment-construction-materials-store',
  'ic-segment-hardware-store',
  'ic-segment-electrical-materials-store',
  'ic-segment-hydraulic-store',
  'ic-segment-lumber-store',
  'ic-segment-pool-store',
  'ic-segment-agricultural-store',
  'ic-segment-market',
  'ic-segment-others',
  'ic-segment-paint-store-circle',
  'ic-segment-construction-materials-store-circle',
  'ic-segment-hardware-store-circle',
  'ic-segment-electrical-materials-store-circle',
  'ic-segment-hydraulic-store-circle',
  'ic-segment-lumber-store-circle',
  'ic-segment-pool-store-circle',
  'ic-segment-agricultural-store-circle',
  'ic-segment-market-circle',
  'ic-segment-others-circle',
];
@NgModule({
  imports: [CommonModule, MatIconModule],
  exports: [MatIconModule],
})
export class IconsModule {
  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {
    this.registerIcons();
  }

  private registerIcons(): void {
    const icons: string[] = ICONS_CONFIG;

    icons.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `../../assets/icons/${icon}.svg`
        )
      );
    });
  }
}
