import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiBridgeService } from 'src/app/core/api-bridge.service';
import {
  ApplicationInsightsService,
  EventName,
} from 'src/app/core/application-insights.service';
import { AuthService } from 'src/app/core/auth.service';
import { StorageKey, StorageService } from 'src/app/core/storage.service';
import { NetworkService } from '../../../core/network.service';
import { PWAService } from '../../../core/pwa.service';
import { SharedModule } from '../../shared.module ';

export enum HeaderType {
  HOME = 'home',
  FILTER = 'filter',
  OTHER = 'other',
  HOME_DESKTOP = 'home-desktop',
}

export enum UserType {
  SUPERVISOR = 'supervisor',
  SELLER = 'seller',
  EXECUTIVE = 'executive',
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
  standalone: true,
  imports: [SharedModule],
})
export class HeaderComponent {
  @Input() public showLogout!: boolean;
  @Output() public logoutButton = new EventEmitter();
  @Output() public backPage = new EventEmitter();
  @Input() public userManual: boolean = false;

  profileName: string = '';
  headerType: HeaderType = HeaderType.HOME;

  showsuggesion$!: Observable<boolean>;
  showNetworkStatus$!: Observable<boolean>;
  HeaderTypeEnum = HeaderType;

  userType: UserType = UserType.SELLER;
  UserTypeEnum = UserType;

  selectedButton: string = 'dashboard';
  constructor(
    private location: Location,
    private authService: AuthService,
    private api: ApiBridgeService,
    private readonly pwa: PWAService,
    private readonly networkService: NetworkService,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private router: Router,
    private readonly storageService: StorageService
  ) {
    this.showsuggesion$ = this.pwa.pwaSuggestion;
    this.showNetworkStatus$ = this.networkService.networkStatus;
  }

  ngOnInit(): void {
    if (!this.userManual) {
      this.api.get('user_data').subscribe((profile: any) => {
        this.profileName = profile.name.split(' ')[0];
        this.storageService.saveData(StorageKey.ClientEmail, profile.email);
        this.userType = profile.user_type;
        this.applicationInsightsService.logEvent(EventName.HomeSuccess);
      });
    }
    if (this.location.path().indexOf('/seller/dashboard/report') > -1) {
      this.selectedButton = 'report';
    }
  }

  install() {
    this.pwa.installPWA();
  }

  close() {
    this.pwa.cancelSuggestion();
  }

  getHeaderType(): HeaderType {
    document.body.style.background = 'white';
    if (this.location.path().indexOf('/client/list') > -1) {
      this.headerType = HeaderType.HOME;
    } else if (this.location.path().indexOf('/filter') > -1) {
      this.headerType = HeaderType.FILTER;
    } else if (this.location.path().indexOf('/seller/dashboard') > -1) {
      this.headerType = HeaderType.HOME_DESKTOP;
    } else {
      this.headerType = HeaderType.OTHER;
    }
    return this.headerType;
  }

  logout(): void {
    this.storageService.clearData();
    this.storageService.clearServiceWorkerCache();
    this.applicationInsightsService.logEvent(EventName.Logout);
    this.authService.logout();
  }
  usabilityManual(isDesktop: boolean = false) {
    const link = document.createElement('a');
    if (isDesktop) {
      link.href = 'assets/manuals/dashboard-desktop.pdf';
    } else {
      link.href =
        this.userType !== this.UserTypeEnum.SELLER
          ? 'assets/manuals/usability-supervisor.pdf'
          : 'assets/manuals/usability.pdf';
    }
    link.download = 'manual_de_usabilidade.pdf';
    link.click();
  }
  backButton(): void {
    if (this.location.path().indexOf('/client/comment') > -1) {
      this.router.navigate([
        `/product/list/${this.location.path().substring(16)}`,
      ]);
    } else if (
      this.location.path().indexOf('/product/list/') > -1 ||
      this.location.path().indexOf('/client/filter/') > -1
    ) {
      this.router.navigate(['/client/list/']);
    } else {
      this.location.back();
    }
  }

  dashboard(): void {
    this.router.navigate([
      this.headerType === HeaderType.HOME_DESKTOP
        ? '/seller/dashboard/home'
        : '/seller/list',
    ]);
    this.selectedButton = 'dashboard';
  }
  report(): void {
    this.router.navigate(['/seller/dashboard/report']);
    this.selectedButton = 'report';
  }
}
