import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import {
  DialogWarningSellerComponent,
  DialogWarningSellerData,
} from '../shared/components/dsh-dialog-warning-seller/dsh-dialog-warning-seller.component';
import { DialogService } from './dialog.service';

@Injectable()
export class ForceSessionInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly dialogService: DialogService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      filter((event) => event instanceof HttpResponse),
      tap((event: HttpResponse<any>) => {
        const currentIngestedDate = event.headers.get('ingested-date');
        if (!currentIngestedDate) {
          return;
        }

        const lastIngestedDate = sessionStorage.getItem('lastIngestedDate');
        if (
          lastIngestedDate &&
          new Date(lastIngestedDate).getTime() !==
            new Date(currentIngestedDate).getTime()
        ) {
          this.accessErrorDialog();
        }
        sessionStorage.setItem('lastIngestedDate', currentIngestedDate);
      })
    );
  }

  private accessErrorDialog() {
    this.dialogService
      .openDialog(DialogWarningSellerComponent, {
        data: {
          buttonText: 'VOLTAR',
          message:
            'Houve uma atualização nos seus dados. Para garantir a melhor experiência, pedimos que você volte para a tela inicial. Obrigado pela compreensão!',
        } as DialogWarningSellerData,
      })
      .afterClosed()
      .pipe(
        tap(() => {
          this.router.navigate(['/']).then(() => {
            window.location.reload();
          });
        })
      )
      .subscribe();
  }
}
