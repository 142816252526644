import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiBridgeService } from 'src/app/core/api-bridge.service';
import {
  IndicatorPercentage,
  ReportClientVisited,
  Seller,
  SellerSellStatistics,
  SellerStatistics,
} from './seller.model';

@Injectable({
  providedIn: 'root',
})
export class SellerService {
  constructor(private readonly apibridge: ApiBridgeService) {}

  getSellers(): Observable<Seller[]> {
    return this.apibridge.get('supervisor/sellers').pipe(
      map((response: any) => {
        return response.sellers.map((seller: any) => {
          return {
            id: seller.id,
            code: seller.seller_code,
            name: seller.name + ' ' + seller.surname,
            lastConnection: seller.last_access_date,
            monthlyConnections: seller.monthly_connections,
          };
        });
      })
    );
  }

  getSellerStatistics(sellerId: string): Observable<SellerStatistics> {
    return this.apibridge
      .get(`supervisor/sellers/${sellerId}/sellersstatics`)
      .pipe(
        map((response: any) => {
          const roundFeedback = (number: number) => {
            const feedbackTotal: number =
              response.positive_feedback +
              response.negative_feedback +
              response.neutral_feedback;
            return feedbackTotal > 0
              ? Math.round((number * 10000) / feedbackTotal) / 100
              : '';
          };
          const seller: SellerStatistics = {
            mostSearchedClient: response.most_searched_client,
            totalClients: response.total_clients,
            uniqueClientsSearched: response.total_clients_searched,
            monthlyConnectionsData: response.connections_per_day,
            clientsNotSearched:
              response.total_clients - response.total_clients_searched,
            pctPositiveFeedback: `${roundFeedback(response.positive_feedback)}`,
            pctNegativeFeedback: `${roundFeedback(response.negative_feedback)}`,
            pctNullFeedback: `${roundFeedback(response.neutral_feedback)}`,
          };
          return seller;
        })
      );
  }

  getSellerSellStatistics(sellerId: string): Observable<SellerSellStatistics> {
    return this.apibridge
      .get(`supervisor/sellers/${sellerId}/sellsstatics`)
      .pipe(
        map((response: any) => {
          const seller: SellerSellStatistics = {
            uniqueClientsSoldTo: response.unique_clients_sold_to,
            uniqueProductsCategoriesSold:
              response.unique_products_categories_sold,
            totalCategories: response.total_categories,
            uniqueProductsSold: response.unique_products_sold,
            totalProducts: response.total_products,
            coverage: response.coverage,
            precision: response.precision,
            salesDate: response.sales_date,
          };
          return seller;
        })
      );
  }
  getGlobalIndicator(): Observable<IndicatorPercentage> {
    return this.apibridge.get('supervisor/global_indicators').pipe(
      map((response: any) => {
        return this.calculateIndicatorPercentages(response);
      })
    );
  }

  getClientsVisited(): Observable<ReportClientVisited> {
    return this.apibridge.get('reports/supervisors/visitedclients');
  }

  private calculateIndicatorPercentages(data: {
    proccesed_month: string;
    positive_feedback: number;
    negative_feedback: number;
    neutral_feedback: number;
    total_clients: number;
    analyzed_clients: number;
    users_per_day: [];
  }): IndicatorPercentage {
    const {
      positive_feedback,
      negative_feedback,
      neutral_feedback,
      total_clients,
      analyzed_clients,
      users_per_day,
    } = data;
    const totalFeedback =
      positive_feedback + negative_feedback + neutral_feedback;

    const positive_percentage =
      totalFeedback === 0
        ? '0'
        : ((positive_feedback / totalFeedback) * 100).toFixed(2);
    const negative_percentage =
      totalFeedback === 0
        ? '0'
        : ((negative_feedback / totalFeedback) * 100).toFixed(2);
    const neutral_percentage =
      totalFeedback === 0
        ? '0'
        : ((neutral_feedback / totalFeedback) * 100).toFixed(2);
    const analyzed_percentage =
      total_clients === 0 ? 0 : (analyzed_clients / total_clients) * 100;

    return {
      positive_percentage,
      negative_percentage,
      neutral_percentage,
      analyzed_percentage,
      total_clients,
      analyzed_clients,
      users_per_day,
    };
  }
}
