import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor() {}

  isMobile(): boolean {
    const userAgent = window.navigator.userAgent;
    const isMobileUserAgent = /iPhone|iPad|iPod|Android/i.test(userAgent);
    const isSmallScreen = window.innerWidth < 420;
    return isMobileUserAgent || isSmallScreen;
  }
}
