import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ClientDetail,
  CustomerType,
  getCustomerTypeById,
} from 'src/app/modules/client/client.model';
import { NetworkService } from '../../../core/network.service';
import { Status } from '../alert-message/alert-message.component';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss'],
})
export class ClientInfoComponent implements OnInit {
  @Input() client!: ClientDetail;
  showMoreInfo: boolean = false;

  @Input() status: Status = Status.Error;
  showNetworkStatus$!: Observable<boolean>;
  statusEnum = Status;
  icon!: string;
  segment!: string;
  constructor(private readonly networkService: NetworkService) {
    this.showNetworkStatus$ = this.networkService.networkStatus;
  }

  ngOnInit(): void {
    const clientType: CustomerType = getCustomerTypeById(
      this.client.customer_type_id
    );
    this.icon = `${clientType.icon}-circle`;
    this.segment = clientType.name;
  }
}
