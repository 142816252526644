@if (client) {
<div class="item-client">
  <div
    class="type-logo"
    [ngClass]="client.status === 'public' ? 'public' : 'wallet'"
  >
    <div class="logo-container">
      <mat-icon [inline]="true" class="material-icons logo" [svgIcon]="icon">
      </mat-icon>
    </div>
  </div>
  <div class="info">
    <span class="title">{{ client.name }}</span>
    <span class="subtitle">CNPJ: {{ client.cnpj }}</span>
  </div>
  <div class="access-logo" (click)="openClient()">
    <div class="logo-container">
      <mat-icon
        [inline]="true"
        class="material-icons logo"
        svgIcon="ic-right-circle"
      >
      </mat-icon>
    </div>
  </div>
</div>
}
