import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Seller } from '../../../modules/seller/seller.model';

export enum SellerKey {
  Seller = 'seller',
}
@Component({
  selector: 'dsh-seller-item',
  templateUrl: './seller-item.component.html',
  styleUrls: ['./seller-item.component.scss'],
})
export class SellerItemComponent implements OnInit {
  @Input() seller!: Seller;
  @Input() isSelected: boolean = false;
  @Output() selectSeller = new EventEmitter<Seller>();

  isDesktop: boolean = false;

  constructor(
    private readonly routerService: Router,
    private readonly location: Location
  ) {}

  ngOnInit() {
    if (this.location.path().indexOf('/client/list') > -1) {
      this.isDesktop = false;
    } else if (this.location.path().indexOf('/seller/desktop/home') > -1) {
      this.isDesktop = true;
    }
  }
  openSeller(): void {
    this.saveData(this.seller);
    this.selectSeller.emit(this.seller);
    !this.isDesktop &&
      this.routerService.navigate(['seller/detail', this.seller.id]);
  }

  saveData(seller: Seller) {
    localStorage.removeItem(SellerKey.Seller);
    localStorage.setItem(SellerKey.Seller, JSON.stringify(seller));
  }
}
