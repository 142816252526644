import { Component, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, EMPTY, finalize, Observable, tap } from 'rxjs';
import { ApplicationInsightsService, EventName, } from 'src/app/core/application-insights.service';
import { NetworkService } from 'src/app/core/network.service';
import { Status } from '../../../shared/components/alert-message/alert-message.component';
import { SellerKey } from '../../../shared/components/dsh-seller-item/seller-item.component';
import { MonthlyConnections, Seller, SellerSellStatistics, SellerStatistics, } from '../seller.model';
import { SellerService } from '../seller.service';


@Component({
  selector: 'app-detail-list',
  templateUrl: './seller-detail.component.html',
  styleUrls: ['./seller-detail.component.scss'],
})
export class SellerDetailComponent implements OnInit {
  status = signal<Status>(Status.Warning);
  noSellers = signal<boolean>(false);
  showNetworkStatus$!: Observable<boolean>;
  offlineStatus = signal<Status>(Status.Offline);

  isLoading = signal<boolean>(false);

  sellerSellStatistics = signal<SellerSellStatistics>({} as SellerSellStatistics);
  sellerStatistics = signal<SellerStatistics>({} as SellerStatistics);

  searchText = signal<string>('');
  messageList = signal<string>('Vendedor não encontrado.');
  elements = signal<string[]>(['id', 'code', 'lastConnection', 'monthlyConnections']);
  date = new Date();
  sellerId = signal<string>('');

  sellerGeneralData!: Seller;
  constructor(
    private readonly sellerService: SellerService,
    private readonly route: ActivatedRoute,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly networkService: NetworkService,
    private readonly routerService: Router
  ) {
    this.showNetworkStatus$ = this.networkService.networkStatus;
  }

  ngOnInit(): void {
    const sellerId = this.route.snapshot.paramMap.get('sellerId');
    if (sellerId) {
      this.sellerId.set(sellerId);

    this.sellerService
    .getSellerStatistics(this.sellerId())
    .pipe(
      tap((client: SellerStatistics) => {
        this.sellerStatistics.set(client);
      }),
      finalize(() => (this.isLoading.set(false))),
      catchError(() => this.catchErrrorLogs())
    )
    .subscribe();

  this.sellerService
    .getSellerSellStatistics(this.sellerId())
    .pipe(
      tap((client: SellerSellStatistics) => {
        this.sellerSellStatistics.set(client);
      }),
      finalize(() => (this.isLoading.set(false))),
      catchError(() => this.catchErrrorLogs())
    )
    .subscribe();

  this.getSellerGeneralData();
    }
  }

  getSellerGeneralData() {
    const data = localStorage.getItem(SellerKey.Seller);
    if (data) {
      this.sellerGeneralData = JSON.parse(data);
    } else {
      this.routerService.navigate(['seller/list']);
    }
  }

  searchbox(value: string): void {
    this.searchText.set(value);
  }

  toSorted(sellers: Seller[]): Seller[] {
    return sellers.sort((a, b) => b.monthlyConnections - a.monthlyConnections);
  }

  hasConnections(connections: MonthlyConnections[]) {
    return connections.every((item: any) => item.value === 0);
  }

  private catchErrrorLogs(): Observable<never> {
    this.noSellers.set(true);
    this.applicationInsightsService.logEvent(EventName.NotFoud, {
      state_not_found: 'seller',
    });
    return EMPTY;
  }
}
