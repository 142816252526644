<div class="content-hands">
  <div class="hand-container">
    <span class="title positive">Positivo</span>
    <div class="hand">
      <mat-icon
        [inline]="true"
        class="material-icons"
        [svgIcon]="'dsh-ic-hand-good'"
      >
      </mat-icon>
    </div>
    <span class="bold">{{ positiveFeedback }}%</span>
  </div>
  <div class="hand-container">
    <span class="title negative">Negativo</span>
    <div class="hand">
      <mat-icon
        [inline]="true"
        class="material-icons"
        [svgIcon]="'dsh-ic-hand-bad'"
      >
      </mat-icon>
    </div>
    <span class="bold">{{ negativeFeedback }}%</span>
  </div>
  <div class="hand-container">
    <span class="title null">Nenhum</span>
    <div class="hand">
      <mat-icon
        [inline]="true"
        class="material-icons"
        [svgIcon]="'dsh-ic-null'"
      >
      </mat-icon>
    </div>
    <span class="bold">{{ nullFeedback }}%</span>
  </div>
</div>
