import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Product } from '../../../modules/product/product.model';

export enum HandType {
  GOOD = 'good',
  BAD = 'bad',
  EMPTY = 'empty',
}

export enum HandFeedbackOperation {
  POST = 'post',
}

@Component({
  selector: 'app-hands',
  templateUrl: './hands.component.html',
  styleUrls: ['./hands.component.scss'],
})
export class HandsComponent implements OnChanges {
  @Input() product!: Product;
  @Output() handSelect = new EventEmitter<{
    handFeedbackOperation: HandFeedbackOperation;
    selection: boolean | null;
  }>();
  HandTypeEnum = HandType;
  goodSelected: boolean = false;
  badSelected: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['product']) {
      if (this.product.feedback !== undefined) {
        if (this.product.feedback?.state === true) {
          this.goodSelected = true;
          this.badSelected = false;
        } else {
          this.goodSelected = false;
          this.badSelected = true;
        }
        if (this.product.feedback?.state === null) {
          this.goodSelected = false;
          this.badSelected = false;
        }
      }
    }
  }

  onClickHand(handType: HandType): void {
    this.setFeedback(handType);
    if (handType === HandType.GOOD && !this.badSelected) {
      this.goodSelected = !this.goodSelected;
    }
    if (handType === HandType.BAD && !this.goodSelected) {
      this.badSelected = !this.badSelected;
    }
    if (handType === HandType.GOOD && this.badSelected) {
      this.badSelected = !this.badSelected;
      this.goodSelected = !this.goodSelected;
    }
    if (handType === HandType.BAD && this.goodSelected) {
      this.goodSelected = !this.goodSelected;
      this.badSelected = !this.badSelected;
    }
  }

  setFeedback(handType: HandType): void {
    if (this.goodSelected && handType === HandType.GOOD) {
      this.makePostRequest(null);
    } else if (this.badSelected && handType === HandType.BAD) {
      this.makePostRequest(null);
    } else if (handType === HandType.GOOD) {
      this.makePostRequest(true);
    } else if (handType === HandType.BAD) {
      this.makePostRequest(false);
    }
  }

  private makePostRequest(selection: boolean | null): void {
    this.handSelect.emit({
      handFeedbackOperation: HandFeedbackOperation.POST,
      selection,
    });
  }

}
