import { Component, Input, OnChanges } from '@angular/core';
import { ClientVisited } from 'src/app/modules/seller/seller.model';

@Component({
  selector: 'dsh-report-seller-feedback',
  templateUrl: './dsh-report-seller-feedback.component.html',
  styleUrls: ['./dsh-report-seller-feedback.component.scss'],
})
export class SellerReportFeedbackComponent implements OnChanges {
  @Input() clientVisited!: ClientVisited;

  positive_percentage: string = '';
  negative_percentage: string = '';
  total_recommendation: number = 0;

  ngOnChanges(): void {
    this.total_recommendation = this.clientVisited.total_recommendation;

    this.positive_percentage =
      this.total_recommendation === 0
        ? '0'
        : (
            (this.clientVisited.positive_feedback / this.total_recommendation) *
            100
          ).toFixed(2);
    this.negative_percentage =
      this.total_recommendation === 0
        ? '0'
        : (
            (this.clientVisited.negative_feedback / this.total_recommendation) *
            100
          ).toFixed(2);
  }
}
