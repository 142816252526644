@if (sellerStatistics) {
  <div class="chart-container">
    @if (!hasConnections(sellerStatistics.monthlyConnectionsData)) {
      <h2>Conexiones mensuais</h2>
      <dsh-chart-line
        [chartData]="sellerStatistics.monthlyConnectionsData"
        [xScale]="'Dias do mês'"
        [yScale]="'Quantia'"
      ></dsh-chart-line>
    } @else {
      <p>O vendedor ainda não tem conexões</p>
    }
    <h2>Devolução do vendedor</h2>
    <dsh-seller-feedback
      [positiveFeedback]="sellerStatistics.pctPositiveFeedback"
      [negativeFeedback]="sellerStatistics.pctNegativeFeedback"
      [nullFeedback]="sellerStatistics.pctNullFeedback"
    ></dsh-seller-feedback>
  </div>
} @else {
  <div class="chart-container">
    <h2>Conexiones mensuais</h2>
    <ngx-skeleton-loader
      count="1"
      [theme]="{
      'height.px': 190,
    }"
    />
    <h2>Devolução do vendedor</h2>
    <div class="skeleton-feedback">
      <ngx-skeleton-loader
        count="3"
        [theme]="{
      'height.px': 100,
      'width.px': 70,
      'margin.px': 16,
    }"/>
    </div>
  </div>
}
