import { Pipe, PipeTransform } from '@angular/core';

const SEARCH_KEYS = ['name', 'cnpj'];
@Pipe({
  name: 'filterList',
})
export class FilterListPipe implements PipeTransform {
  transform(list: any[], searchText: string, keys?: string[]): any[] {
    if (searchText)
      return this.filterListBySearchText(list, searchText, keys || SEARCH_KEYS);
    return list;
  }

  filterListBySearchText(
    list: any[],
    searchText: string,
    keys: string[]
  ): any[] {
    return list.filter((element: any) =>
      this.matchesSearchText(element, searchText, keys)
    );
  }

  matchesSearchText(element: any, searchText: string, keys: string[]): boolean {
    return keys.some((key) =>
      element[key]?.toString().toLowerCase().includes(searchText.toLowerCase())
    );
  }
}
