import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Comment } from 'src/app/modules/client/client.model';
import { NetworkService } from '../../../core/network.service';

@Component({
  selector: 'app-comment-card',
  templateUrl: './comment-card.component.html',
  styleUrls: ['./comment-card.component.scss'],
})
export class CommentCardComponent {
  @Input() comment!: Comment;

  @Output() commentEdited = new EventEmitter();
  @Output() commentDeleted = new EventEmitter();

  showNetworkStatus$!: Observable<boolean>;
  constructor(private readonly networkService: NetworkService) {
    this.showNetworkStatus$ = this.networkService.networkStatus;
  }

  delete(): void {
    this.commentDeleted.emit(this.comment);
  }

  edit(): void {
    this.commentEdited.emit(this.comment);
  }
}
