<div class="client-info-container">
  <div class="content">
    <div class="subject">
      <div class="name">
        <span>{{ client.legal_name }}</span>
      </div>
      <div class="address">
        <span>{{ client.address }}</span>
      </div>
      <div class="state">
        <span>{{ client.state }}-{{ client.state }}</span>
      </div>
    </div>
    <div layout="column">
      <div class="content-logo">
        <mat-icon [inline]="true" class="material-icons logo" [svgIcon]="icon">
        </mat-icon>
      </div>
    </div>
  </div>
  <div class="buttons">
    <button
      [disabled]="!client"
      mat-stroked-button
      color="primary"
      class="more-info-button"
      (click)="showMoreInfo = !showMoreInfo"
    >
      <mat-icon
        iconPositionEnd
        class="material-icons"
        [svgIcon]="!showMoreInfo ? 'ic-expand-more' : 'ic-expand-less'"
      ></mat-icon>
      <span class="primary-button-text"> Mais informações </span>
    </button>
    <button
      [disabled]="
        !(showNetworkStatus$ | async) && status === statusEnum.Offline
      "
      mat-stroked-button
      class="comment-button"
      [routerLink]="['/client/comment', client.id]"
    >
      <span class="primary-button-text">Comentários</span>
      <mat-icon
        iconPositionEnd
        [inline]="true"
        class="material-icons"
        svgIcon="ic-message"
      ></mat-icon>
    </button>
  </div>
  @if (showMoreInfo) {
  <div class="more-info">
    <div class="container-info">
      <div class="row">
        <div class="key">Razão Social:</div>
        <div class="value">
          {{ client.legal_name }}
        </div>
      </div>
      <div class="row">
        <div class="key">Nome Fantasia:</div>
        <div class="value">{{ client.name }}</div>
      </div>
      <div class="row">
        <div class="key">Segmento:</div>
        <div class="value">{{ segment }}</div>
      </div>
      <div class="row">
        <div class="key">CNPJ:</div>
        <div class="value">{{ client.cnpj }}</div>
      </div>
      <div class="row">
        <div class="key">Endereço:</div>
        <div class="value">
          <a
            style="text-decoration: underline; cursor: pointer"
            target="_blank"
            [href]="
              'http://maps.google.com/?q=' +
              client.address +
              ' ' +
              client.city +
              ' ' +
              client.state +
              ''
            "
          >
            {{ client.address }}
          </a>
        </div>
      </div>
      <div class="row">
        <div class="key">Cidade/Município:</div>
        <div class="value">{{ client.city }}</div>
      </div>
      <div class="row">
        <div class="key">Estado:</div>
        <div class="value">{{ client.state }}</div>
      </div>
    </div>
  </div>
  }
</div>
