import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import {
  ApplicationInsights,
  IExceptionTelemetry,
  IMetricTelemetry,
  IPageViewTelemetry,
  ITraceTelemetry,
} from '@microsoft/applicationinsights-web';
import { environment } from '../../environments/environment';
import { AmplitudeService } from './amplitude.service';
import { ApiBridgeService } from './api-bridge.service';

export enum EventName {
  LoginSuccess = 'LOGIN',
  Logout = 'LOGOUT',
  ErrorTokenLogout = 'ERROR - TOKEN LOGOUT',
  NotFoud = 'NOT FOUND',
  Filter = 'FILTER',
  RecommendationClick = 'RECOMMENDATIONS - CLICK',
  RecommendationInit = 'RECOMMENDATIONS - INIT',
  RecommendationFeedback = 'RECOMMENDATION - FEEDBACK',
  CommentInit = 'COMMENT - INIT',
  CommentCreation = 'COMMENT - CREATE',
  CommentModification = 'COMMENT - MODIFY',
  FilterProducts = 'FILTER - PRODUCTS',
  AccessDenied = 'ACCESS DENIED',
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  private readonly appInsights: ApplicationInsights;

  constructor(
    private readonly authService: MsalService,
    private readonly apiBridgeService: ApiBridgeService,
    private readonly amplitudeService: AmplitudeService
  ) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.instrumentationKey,
        enableAutoRouteTracking: true,
      },
    });
  }

  init(): void {
    if (environment.metrics) {
      this.appInsights.loadAppInsights();
    }
  }

  logPageView(name?: string, url?: string): void {
    const pageView: IPageViewTelemetry = { name, uri: url };
    this.appInsights.trackPageView(pageView);
  }

  logEvent(name: string, properties?: { [key: string]: any }): void {
    const eventProperties = {
      userName: this.authService.instance.getActiveAccount()?.username,
      ...properties,
    };
    this.appInsights.trackEvent({ name }, eventProperties);
    this.logEventFligoo({ event: name, ...eventProperties });
    this.logEventAmplitude(name, eventProperties);
  }

  logEventFligoo(eventProperties: any): void {
    this.apiBridgeService.post('user_logs', eventProperties).subscribe();
  }

  logEventAmplitude(eventName: string, eventData?: any): void {
    this.amplitudeService.trackEvent(eventName, eventData);
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ): void {
    const metric: IMetricTelemetry = { name, average };
    this.appInsights.trackMetric(metric, properties);
  }

  logException(exception: Error, severityLevel?: number): void {
    const exceptionTelemetry: IExceptionTelemetry = {
      exception,
      severityLevel,
    };
    const properties = {
      username: this.authService.instance.getActiveAccount()?.username,
    };
    this.appInsights.trackException(exceptionTelemetry, properties);
  }

  logTrace(message: string, properties?: { [key: string]: any }): void {
    const trace: ITraceTelemetry = { message };
    this.appInsights.trackTrace(trace, properties);
  }
}
