<div class="container">
  <div class="hands">
    <div class="item hand">
      <mat-icon
        [inline]="true"
        class="material-icons"
        [svgIcon]="'dsh-ic-hand-good'"
      >
      </mat-icon>
      <div class="good">10%</div>
    </div>
    <div class="item hand">
      <mat-icon
        [inline]="true"
        class="material-icons"
        [svgIcon]="'dsh-ic-hand-bad'"
      >
      </mat-icon>
      <div class="bad">10%</div>
    </div>
  </div>
  <div class="item">
    <div class="bold">Total:</div>
    <div>450</div>
  </div>
</div>
