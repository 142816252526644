import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { DeviceService } from './device.service';

@Injectable({
  providedIn: 'root',
})
export class MobileGuard implements CanActivate {
  constructor(
    private readonly deviceService: DeviceService,
    private readonly router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isMobile = this.deviceService.isMobile();

    if (!isMobile) {
      this.router.navigate(['/seller/dashboard/home']);
      return false;
    }

    return true;
  }
}
